<template>

    <b-modal
          :title="title"
          :centered="centered"
          :static="true"
          :lazy="lazy"
          v-model="shown"
            button-size="lg"
            @ok="ok"
        >
<!--
    <vue-bootstrap-typeahead
        v-if="canSearch"
        v-model="searched"
        :data="data"
        :minMatchingChars="searchMinMatchingChars"
        :serializer="searchSerializer"
        :placeholder="searchPlaceHolder"
        :prepend="searchPrepend"
        ref="searchField"
        @hit="search_hit"
    >
    </vue-bootstrap-typeahead>-->

   <!-- <helper-note>
      <slot name="helper-note">
        Privilégiez toujours la recherche.<br/>
        Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
        Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou si vous êtes certain qu'il s'agit d'un nouveau compétiteur inconnu dans la base, saisissez les informations ci-dessous
      </slot>
    </helper-note>-->
    <b-form-group label="Prénom :">
        <b-form-input type="text"
            v-model="firstname"
            required
            ref="firstnameField"
        />
    </b-form-group>

    <b-form-group label="Nom :">
        <b-form-input
            v-model="lastname"
            required
            ref="lastnameField"
        />
    </b-form-group>

    <b-form-group label="N° de licence FFVL">
      <b-form-input
          v-model="federalNumber"
          ref="federalNumberField"
      />
    </b-form-group>
    </b-modal>

</template>

<script>
//import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

export default {
  //components:{ VueBootstrapTypeahead },
  props:{
    show: Boolean,
    value: { type:Boolean, default:false },
    title: { type:String, default:'' },
    centered: { type: Boolean, default:false},
    lazy: {type:Boolean, default: true },
    canSearch: { type: Boolean, default:true },
    searchData: { type: Array, default:new Array()},
    searchSerializer: { type: Function, default: (i)=>i },
    searchMinMatchingChars: { type: Number, default: 2 },
    searchPlaceHolder: { type:String, default: 'Critères...'},
    searchPrepend: { type: String, default: ''}
  },
  data(){
    return {
      selectedByTypeAhead: null,
      searched: '',
      firstname: '',
      lastname:'',
      federalNumber:'',
      shown: this.show,
    }
  },
  methods:{
    search_hit(selected){
      this.selectedByTypeAhead = selected;
      this.firstname = selected.firstname;
      this.lastname = selected.lastname;
      this.federalNumber = selected.federalNumber;
    },
    ok(){
      this.$emit("ok", { selected: this.selectedByTypeAhead, firstname: this.firstname, lastname: this.lastname, federalNumber: this.federalNumber});
    },
    setfocus(field){
      if(field != null)
        this.$refs[field + 'Field'].setfocus();
      else if(this.canSearch)
        this.$refs.seacrhField.setfocus();
      else
        this.$refs.firstnameField.setfocus();
    },
    data(){
      return this.searchData;
    },
  },
}
</script>

